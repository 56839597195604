'use client';
import { FunctionComponent } from 'react';
import { useIam } from '../../../../../services/IAm/hooks';
import { ButtonLink } from '../../../../../components/ButtonLink';

export const AdminDashboardButton: FunctionComponent<{ className?: string }> = ({ className }) => {
    const { iam } = useIam();

    if (iam.authenticated && iam.educators && iam.educators.length > 0) {
        return (
            <ButtonLink
                className={className}
                preset="primary"
                href={process.env.NEXT_PUBLIC_ADMIN_URL as string}
            >
                Admin Dashboard
            </ButtonLink>
        );
    }

    return null;
};
